<template>
  <div class="app-dashboard">
    <AppHeader/>
    <div class="app-dashboard-container">
      <AppSidebar/>
      <div class="app-content container-fluid">
        <slot/>
      </div>
    </div>
  </div>
</template>
<script>
import AppHeader from '../components/AppHeader'
import AppSidebar from '../components/AppSidebar'
export default {
  name: "AppLayoutDashboard",
  components: {AppHeader, AppSidebar}
}
</script>