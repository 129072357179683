<template>
  <div class="app-header">
    <div class="app-logo">
      <router-link to="/dashboard"><img src="@/assets/img/logo.svg" alt="iCore Logo"/></router-link>
    </div>
    <div class="app-header-right">
      <div class="dropdown notification">
        <a @click="toggleNotification" class="notification-link"><img src="@/assets/img/icon-bell.svg" alt="Notification"/>
        <span class="badge badge-danger" v-if="totalTrainingList > 0">{{ totalTrainingExpiredList }}</span></a>
        <div class="dropdown-menu dropdown-menu-right notification-list" :class="isOpenNotification ? 'show' : ''"  v-for="(item, i) in trainingExpiredList" :key="i">
          <span class="dropdown-item">Masa berlaku training {{item.trainingType }} akan habis. 
</span>
        </div>
      </div>
      <div class="dropdown profile">
        <button type="button" class="btn btn-success" @click='logout'>Logout</button>
        <!-- <a @click="toggleProfileDropdown" class="profile-link"><div class="circle">DI</div></a>
        <div class="dropdown-menu dropdown-menu-right profile-links" :class="isOpenProfileDropdown ? 'show' : ''">
          <button type="submit" class="dropdown-item" @click='logout'>Logout</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import useTrainingList from './../composables/training/useTrainingList'

export default {
  data() {
        const { totalTrainingExpiredList, trainingExpiredList } = useTrainingList();
    return {
      isOpenNotification: false,
      isOpenProfileDropdown: false,
      trainingExpiredList,
      totalTrainingExpiredList
    }
  },
  methods: {
    toggleNotification() {
      if(this.isOpenProfileDropdown) {
        this.toggleProfileDropdown();
      }
      this.isOpenNotification = !this.isOpenNotification;
    },
    toggleProfileDropdown() {
      if(this.isOpenNotification) {
        this.toggleNotification();
      }
      this.isOpenProfileDropdown = !this.isOpenProfileDropdown;
    },
    logout() {
      this.$store.dispatch("logout")
      .then(() => {
        return this.$router.push("/login"); 
      })
    }
  }
};
</script>