<template>
  <div class="app-sidebar">
    <div class="profile-overview">
      <img :src="userData.photo" :alt="userData.name" height="80px"/>
      <h2 class="profile-name">Hi, <span>{{ userData.name }}</span> !</h2>
      <div class="profile-position">{{ userData.position }}</div>
    </div>
    <div class="profile-about">
      <h3 class="profile-about-title">Tentang Saya</h3>
      <div class="profile-about-details">
        <div class="profile-about-detail">
          <img src="@/assets/img/icon-calendar.svg" alt="Birthday"/>
          <span>{{ userData.place_of_birth }}, {{ moment(userData.date_of_birth).format('DD MMM YYYY') }}</span>
        </div>
        <div class="profile-about-detail">
          <img src="@/assets/img/icon-home.svg" alt="Birthday"/>
          <span>{{ userData.address }}</span>
        </div>
        <div class="profile-about-detail">
          <img src="@/assets/img/icon-phone.svg" alt="Phone"/>
          <span>{{ userData.contact }}</span>
        </div>
        <div class="profile-about-detail">
          <img src="@/assets/img/icon-mail.svg" alt="Email"/>
          <span>{{ userData.email }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from 'moment';

export default {
  methods: {
    moment(date) {
      return moment(date).locale('id');
    },
  },
  computed: {
    ...mapState(['userData'])    
  }
}
</script>